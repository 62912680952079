<template>

  <br>

  <div v-if="question">

    <div v-if="question.question_type == 1" class="multiple">


      <img v-if="question.image && !question.video_mp4" class="image" :src="question.image">

      <video :key="question.video_mp4"  v-if="question.video_mp4" class="image" autoplay muted playsinline loop width="100%">
        <source :src="question.video_mp4" type="video/mp4">
        <source :src="question.video_webm" type="video/webm">
        Your browser does not support the video tag.
      </video>


      <div class="right">
        <div class="title">{{ question.desc }}</div>

        <ul class="buttons" v-bind:class="{ 'has_images': question.has_images }">

          <li v-for="answer in question.answers" :key="answer.id" v-on:click="setAnswer(answer.text)"
            :class="{ 'active': answer.text === currentAnswer || currentAnswers.includes(answer.text) }">

            <div class="check">
              <svg class="not-selected" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M384 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
              </svg>
              <svg class="selected" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
              </svg>
            </div>

            <div v-if="answer.image">
              <img style="width:100%;max-width:300px" :src="answer.image">
            </div>

            <div v-else>
              {{ answer.text }}
            </div>
          </li>
        </ul>
      </div>
    </div>


    <div v-if="question.question_type == 2" class="multiple">

      <img v-if="question.image  && !question.video_mp4" class="image" :src="question.image">

      <video  :key="question.video_mp4" v-if="question.video_mp4" class="image" autoplay muted playsinline loop width="100%">
        <source :src="question.video_mp4" type="video/mp4">
        <source :src="question.video_webm" type="video/webm">
        Your browser does not support the video tag.
      </video>


      <div class="right">
        <div class="title">{{ question.desc }} <span style="display: block; font-size: 20px;color:#80bc5c;"> Meerder
            antwoorden
            mogelijk</span></div>


        <ul class="buttons" v-bind:class="{ 'has_images': question.has_images }">


          <li v-for="answer in question.answers" :key="answer.id" v-on:click="setAnswer(answer.text, true)"
            :class="{ 'active': answer.text === currentAnswer || currentAnswers.includes(answer.text) }">

            <div class="check">
              <svg class="not-selected" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M384 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
              </svg>
              <svg class="selected" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
              </svg>
            </div>

            <div v-if="answer.image">
              <img style="width:100%;max-width:300px" :src="answer.image">
            </div>
            <div v-else>
              {{ answer.text }}
            </div>
          </li>
        </ul>
      </div>
    </div>



    <div v-if="question.question_type == 3" class="input has_image">
      <img v-if="question.image" class="image" :src="question.image">
      <div clas="right">
        <div class="title">{{ question.desc }}</div>
        <input class="" v-model="currentAnswer" v-on:change="setAnswer(currentAnswer)" type="text" maxlength="20"
          placeholder="Vul hier je antwoord in">
      </div>
    </div>


    <div v-if="question.question_type == 4" class="drag">

      <div class="title">{{ question.desc }}</div>
      <div class="container">
        <div>
          <div class="image">
            <img :src="question.image">
            <div v-for="(answer, index) in question.answers"
              :style="'left:' + answer.coordinates.x + '%;top:' + answer.coordinates.y + '%;'" :key="index"
              class="droppable" :class="{ current: currentAnswer == answer.text }" @dragover.prevent
              @drop="drop(index, 'single', answer.text)">
              <span class="mobile"><b>{{ letters[index] }}</b></span>
              <span v-if="currentAnswer == answer.text" class="desktop">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 448 512">
                  <path
                    d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg>
              </span>

            </div>
          </div>
          <br><br>

          <div class="desktop">
            <b>Sleep onderstaande icoontje naar de juiste locatie:</b>
            <div class="answers-container">
              <div class="draggable draggable-check" id="test" draggable="true" @dragstart="dragStart">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 448 512">
                  <path
                    d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg>
              </div>
            </div>
          </div>
          <div class="mobile">
            <ul class="buttons" v-bind:class="{ 'has_images': question.has_images }">

              <li v-for="(answer, index) in question.answers" :key="answer.id" v-on:click="setAnswer(answer.text)"
                :class="{ 'active': answer.text === currentAnswer || currentAnswers.includes(answer.text) }">
                {{ letters[index] }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>



    <div v-if="question.question_type == 5" class="drag">

      <div class="title">{{ question.desc }}</div>
      <div class="">
        <div>

          <div class="image">
            <img :src="question.image">

            <div v-for="(answer, index)  in question.answers" :class="{ current: draggedItems[index] }"
              :style="'left:' + answer.coordinates.x + '%;top:' + answer.coordinates.y + '%;'" :key="index"
              class="droppable" @dragover.prevent @drop="drop(index, 'multiple')">
              <span class="mobile"><b>{{ letters[index] }}</b></span>
              {{ draggedItems[index] }}


            </div>
          </div><br><br>
          <div class="desktop">

            <b>Sleep onderstaande nummers naar de juiste locatie:</b>
            <div class="answers-container">
              <div v-for="(answer, index) in filteredAnswers" class="draggable" :id="index" draggable="true"
                @dragstart="dragStart(answer.text, index)" :key="index">
                {{ index + 1 }}</div>
            </div>
          </div>

          <div class="mobile">
            <ul class="buttons" v-bind:class="{ 'has_images': question.has_images }">

              <li v-for="(answer, index) in question.answers" :key="answer.id" v-on:click="setAnswerMobile(index)"
                :class="{ 'active': answer.text === currentAnswer || currentAnswers.includes(answer.text) }">
                {{ letters[index] }}

                <span v-if='multipleMobile[index]'>
                  => {{ multipleMobile[index] }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

export default {
  name: 'Questions',
  props: {
    question: Object,
    prevAnswer: Object
  },

  data() {
    return {
      letters: ['A', 'B', 'C', 'D', 'E', 'F'],
      currentAnswer: this.value,
      currentAnswers: [],
      answersWithImages: false,
      draggedItems: [],
      multipleMobile: [],
    }
  },

  watch: {
    question() {
      this.currentAnswer = this.prevAnswer;
      this.currentAnswers = [];
      this.draggedItems = [];
    }
  },

  computed: {
    givenAnswer() {
      if (this.currentAnswers.length > 0) {
        return JSON.stringify(this.currentAnswers);
      }
      return this.currentAnswer;
    },
    filteredAnswers() {
      return this.question.answers.filter(answer => !this.draggedItems.includes(answer.text));
    }
  },

  methods: {
    setAnswerMobile(index) {

      //  this.multipleMobile[index2] = index;

      let answer;
      answer = index + 1;
      if (this.currentAnswers.length < this.question.answers.length) {
        this.currentAnswers.push(answer.toString());
      } else {
        //emtyy the array first
        this.currentAnswers = [];
        this.multipleMobile = [];
        this.currentAnswers.push(answer.toString());
      }

      this.setAnswer(this.currentAnswers);

      this.multipleMobile[index] = this.currentAnswers.length;


    },

    dragStart(info, index) {
      event.dataTransfer.setData('text/plain', info);
      event.dataTransfer.setData('index', index);
    },
    drop(dropId, type, answer) {
      const draggedItemId = event.dataTransfer.getData('text/plain');
      const index = event.dataTransfer.getData('index');

      if (type == 'multiple') {
        this.currentAnswers[dropId] = draggedItemId;
        this.draggedItems[dropId] = parseInt(index) + 1;
      } else {
        this.currentAnswer = answer;
      }

      this.setAnswer(this.currentAnswer);

    },


    setAnswer(val, multiple = false) {
      if (multiple) {
        const index = this.currentAnswers.indexOf(val);
        if (index !== -1) {
          this.currentAnswers.splice(index, 1);
        } else {
          this.currentAnswers.push(val);
        }
      } else {
        this.currentAnswer = val;
      }

      this.$emit('setAnswer', this.givenAnswer);
    }
  }
};
</script>
