<template>
  <div>
    <div class="header">

      <div class="logo">
        <img src="https://theoriefoutloos.nl/assets/images/brands/logo-theoriefoutloos.svg"
          alt="Theorie Foutloos Homepagina">
      </div>

      <div>
        <span class="icon" v-if="examType.id == 1">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
          </svg>
        </span>

        <span class="icon" v-if="examType.id == 2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
            <path
              d="M280 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h57.7l16.4 30.3L256 192l-45.3-45.3c-12-12-28.3-18.7-45.3-18.7H64c-17.7 0-32 14.3-32 32v32h96c88.4 0 160 71.6 160 160c0 11-1.1 21.7-3.2 32h70.4c-2.1-10.3-3.2-21-3.2-32c0-52.2 25-98.6 63.7-127.8l15.4 28.6C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L418.2 128H480c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H459.6c-7.5 0-14.7 2.6-20.5 7.4L391.7 78.9l-14-26c-7-12.9-20.5-21-35.2-21H280zM462.7 311.2l28.2 52.2c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-28.2-52.2c2.3-.3 4.7-.4 7.1-.4c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-15.5 5.5-29.7 14.7-40.8zM187.3 376c-9.5 23.5-32.5 40-59.3 40c-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40h66.4C242.5 268.8 190.5 224 128 224C57.3 224 0 281.3 0 352s57.3 128 128 128c62.5 0 114.5-44.8 125.8-104H187.3zM128 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
          </svg>
        </span>

        <span class="icon" v-if="examType.id == 3">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
            <path
              d="M280 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h57.7l16.4 30.3L256 192l-45.3-45.3c-12-12-28.3-18.7-45.3-18.7H64c-17.7 0-32 14.3-32 32v32h96c88.4 0 160 71.6 160 160c0 11-1.1 21.7-3.2 32h70.4c-2.1-10.3-3.2-21-3.2-32c0-52.2 25-98.6 63.7-127.8l15.4 28.6C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L418.2 128H480c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H459.6c-7.5 0-14.7 2.6-20.5 7.4L391.7 78.9l-14-26c-7-12.9-20.5-21-35.2-21H280zM462.7 311.2l28.2 52.2c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-28.2-52.2c2.3-.3 4.7-.4 7.1-.4c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-15.5 5.5-29.7 14.7-40.8zM187.3 376c-9.5 23.5-32.5 40-59.3 40c-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40h66.4C242.5 268.8 190.5 224 128 224C57.3 224 0 281.3 0 352s57.3 128 128 128c62.5 0 114.5-44.8 125.8-104H187.3zM128 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
          </svg>
        </span>
      </div>

      <div class="timeLine">
        <span class="bar" :style="'width: ' + progress + '%'">
          <span class="time">{{ currentQuestionNumber }} / {{ amountOfQuestions }}</span>
        </span>
      </div>

      <a href="https://mijn.theoriefoutloos.nl" class="exit">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path
            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
        </svg>
      </a>

    </div>
    <div class="question">

      <div v-if="category">
        <h2>{{ category.name }}</h2>
        <hr>
      </div>


      <div v-if="preload == true" class="preload">
        <h1>We halen je examen op.</h1>

        <svg v-if="preloaded == false" width="50" height="50" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="40" stroke="#e64853" stroke-width="8" fill="none" stroke-dasharray="200" stroke-dashoffset="100">
            <animateTransform attributeType="XML" attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="1s" repeatCount="indefinite" />
          </circle>
        </svg>
        <div>
          <button class="start" v-if="preloaded == true"  v-on:click="start">
            Start examen
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                  d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
            </svg>
          </button>
        </div>
      </div>


      <div v-if="type == 'intro'" class="intro">
        {{ category.name }}
      </div>

      <Questions v-if="currentQuestion && type == 'question'" @setAnswer="setAnswer"
        :question="currentQuestion.question" :prevAnswer="prevAnswer"></Questions>

      <div v-if="type == 'done'">


        <div class="result geslaagd" v-if='passed == true'>Gefeliciteerd,je bent geslaagd</div>
        <div class="result gezakt" v-if='passed == false'>Helaas, je bent gezakt</div>


        <div class="center-button">
          <a class="uitslag" :href=" this.dashhboardUrl + '/gegeven-antwoorden/' + uuid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
            </svg>
            Bekijk hier je uitslag
          </a>
        </div>
      </div>

      <nav v-if="type != 'done'">

        <button v-if="previousQuestion !== false" class="prev" v-on:click="prev">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path
              d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
          </svg>
          Vorige
        </button>

        <span v-if="previousQuestion === false">&nbsp;</span>

        <button class="next" v-on:click="next" v-if="preload == false">
          Volgende
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path
              d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
          </svg>
        </button>

      </nav>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import Questions from './components/Questions.vue';

const UUID = window.UUID;
const apiUrl = window.apiUrl;
const dashhboardUrl = window.dashhboardUrl;
export default {
  name: 'App',
  components: {
    Questions
  },
  data() {
    return {
      preload : true,
      uuid: UUID ?? null,
      dashhboardUrl: dashhboardUrl ?? null,
      cat: [],
      videos: [],
      preloaded: false,
      questionKey: 0,
      timeIn: 0,
      title: '',
      progress: 0,
      duration: 0,
      endtime: 0,
      intro: [],
      type: '',
      page: [],
      question: [],
      allAnswers: [],
      prevAnswer: '',
      currentAnswer: [],
      currentQuestion: [],
      currentCategorie: [],
      currentExam: [],
      response: [],
      answeredQuestions: [],
      previousQuestion: false,
      examType: false,
      nextQuestion: false,
      startTime: 0,
      progressCount: 0,
      category: null,
      passed: false,
      result_url: '',
      value: ''
    };
  },
  created() {
    if (!UUID) {
      this.$router.push('/Free');
    } else {
      this.fetchData();
      this.updateProgressTimer = setInterval(this.startTimer, 100);
    }
  },
  beforeUnmount() {
    clearInterval(this.startTimer);
  },
  methods: {
    start() {
      this.preload = false;
      this.fetchData();
    },
    prev() {
      this.answeredQuestions.pop();
      this.fetchData(this.previousQuestion.uuid);
    },
    setAnswer(answer) {
      this.currentAnswer = answer;
    },
    next() {
      if (this.type == 'intro') {
        this.fetchData(this.category.next);
        return false;
      }

      let data = new FormData();
      data.append('answer', this.currentAnswer);


      if (!this.answeredQuestions.includes(this.currentQuestion.uuid)) {
        axios.request({
          method: 'post',
          url: apiUrl + '/oefenexamen/' + UUID + '/antwoord/' + this.currentQuestion.uuid,
          data: data
        }).then(() => {
          this.currentAnswer = null;
          this.fetchData();
          this.sendNext = false;
        });
        this.answeredQuestions.push(this.currentQuestion.uuid);
      }

    },




    async fetchData(uuid) {

      let response;

      if (uuid) {
        response = await axios.get(apiUrl + '/oefenexamen/' + UUID + '/vraag/' + uuid);
      } else {
        response = await axios.get(apiUrl + '/oefenexamen/' + UUID + '/vraag');
      }

      this.response = response.data;


      console.log(this.response);

      if (this.preload == true) {


        let media = await axios.get(apiUrl + '/oefenexamen/' + UUID + '/media');

        let listOfVideos = media.data.media;

        setTimeout(() => {
          console.log('fallback countdown start');
          this.preloaded = true;
          console.warn("Preloading timed out.");
        }, 10000); // 10 seconden


        const channel = new MessageChannel();

        //send list to serviceworker
        if (navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({
            action: 'cacheVideos',
            message: listOfVideos,
          }, [channel.port2]);



          channel.port1.onmessage = event => {
            if (event.data === 'done') {
              console.log('fallback countdown done');
              this.preloaded = true; // Mark as preloaded
            }
          };
        }

      }

      if (this.preload == false) {


        if (response.data.type) {
          this.type = response.data.type
          this.examType = response.data.examType

          if (this.type == 'done') {
            axios.request({
              method: 'post',
              url: apiUrl + '/oefenexamen/' + UUID + '/afronden'
            }).then((data) => {
              this.passed = data.data.passed;
              this.result_url = data.data.url;

            });
          }
        }
        if (!this.examType) {
          this.examType = 1;
        }


        // start de timeline
        if (response.data.category) {
          this.category = response.data.category;

          if (this.category.duration_per_question) {
            this.startTime = Math.round(new Date().getTime() / 1000);
            this.duration = this.category.duration_per_question;
            this.endtime = this.startTime + this.duration;
            this.startTimer();
          }

          if (this.category.duration) {
            if (this.currentCategorie != this.category.id) {
              this.startTime = Math.round(new Date().getTime() / 1000);
              this.duration = this.category.duration * 60;
              this.endtime = this.startTime + this.duration;
              this.startTimer();
              this.currentCategorie = this.category.id;
            }
          }
        }

          if (this.examType.duration) {
            if (this.currentExam != this.uuid) {
              this.startTime = Math.round(new Date().getTime() / 1000);
              this.duration = this.examType.duration * 60;
              this.endtime = this.startTime + this.duration;
              this.startTimer();
              this.currentExam = this.uuid;
            }
          }



        if (response.data.current) {
          this.currentQuestion = response.data.current;
          this.nextQuestion = response.data.next;
          this.value = response.data.current.value;
        }

        if (response.data.previous) {
          this.previousQuestion = response.data.previous;
        }

        if (response.data.currentQuestionNumber) {
          this.currentQuestionNumber = response.data.currentQuestionNumber;
        }

        if (response.data.amountOfQuestions) {
          this.amountOfQuestions = response.data.amountOfQuestions;
        }

        if (response.data.current) {
          this.prevAnswer = response.data.current.value;
        }

      }
    },
    startTimer() {
      const currentTime = Math.round(new Date().getTime() / 1000);
      this.timeIn = currentTime - this.startTime;
      const progressCount = currentTime - this.startTime;
      const precentage = Math.round(progressCount / this.duration * 100);
      this.progress = precentage;
      this.progressCount = progressCount;
      if (this.duration && this.progress > 99) {
        this.next();
      }
    }
  }
};
window.addEventListener('beforeunload', (event) => {
  event.preventDefault();
});
</script>